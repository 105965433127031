import { ROUTE_PATHS } from './routes-paths';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
import { VpRoute } from '@zeiss/ng-vis-vp-auth/types';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { VisIcon } from '@zeiss/ng-vis-common/types';

export const ROUTE_CONFIG: VpRoute = {
	path: ROUTE_PATHS._,
	canActivate: [AUTH_GUARD],
	data: {
		title: 'vp.lpp.title',
		description: 'vp.lpp.desc',
		icon: VisIcon.SETTINGS_CAMERA,
		showInMenu: true,
		rule: vpRule({
			rule: RULE.access_lpp,
			required: [],
		}),
		routes: [
			{
				path: '',
			},
			{
				path: 'mgmt',
				canActivate: [AUTH_GUARD],
				data: {
					title: 'vp.lpp.routes.mgmt.title',
					description: 'vp.lpp.routes.mgmt.desc',
					icon: VisIcon.SEARCH,
					showInMenu: true,
					rule: vpRule({
						rule: RULE.access_lpp,
						required: [],
					}),
				},
			},
		],
	},
};
